.home-container {
    text-align: center;
    position: relative;
    padding-top: 50px; /* Lower all text slightly */
  }
  
  h1, h2, .nav-links {
    margin-bottom: 20px; /* Add vertical spacing between elements */
  }
  
  .sign-out-button {
    display: block;
    margin: -10px auto; /* Center the button */
  }
  
  .nav-links {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin-top: 160px;
  }
  
  .nav-links li {
    margin: 0 15px;
  }
  
  h2 {
    font-size: 1.2em;
    margin-top: 50px;
  }