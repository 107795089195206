.auth-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 10vh; /* This will add space at the top */
}

.terms-checkbox .amplify-checkbox__label {
  display: flex;
  align-items: flex-start;
  font-size: 0.8em; /* Adjust this value to shrink the text size */
}

.terms-checkbox .amplify-checkbox__input {
  margin-top: 0.3em; /* Adjust this value as needed to fine-tune the alignment */
}

.name-fields {
  display: flex;
  gap: 1em; /* Adjust the gap as needed */
}
