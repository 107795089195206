.page-h2 {
    font-size: 1.2em;
    margin-top: 50px;
    text-align: center; /* Center the text */
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center;
  width: 100%; /* Ensure it takes full width */
}

.page-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust the gap as needed */
  margin-top: 20px;
}

.page-text {
  margin-top: 40px;
  max-width: 850px;
}

.input-field {
  width: 300px; /* Set a specific width */
  max-width: 100%; /* Ensure it doesn't exceed the container */
  padding: 5px; /* Reduced padding for a smaller height */
  margin-top: 10px; /* Space above the input */
  border: 1px solid #ccc; /* Light border */
  font-size: 0.9em; /* Reduced font size */
}

/* New styles for the Save button */
.save-button {
  background-color: yellow; /* Yellow background */
}

/* Style for the Save button when disabled */
.save-button:disabled {
  background-color: lightgray; /* Light gray when disabled */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
  opacity: 0.5; /* Dull effect when disabled */
}

.input-container {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: space-between; /* Space between label and input fields */
  margin-bottom: 10px; /* Add some space between input containers */
}

.book-label {
  flex: 1; /* Allow label to take available space */
  text-align: right; /* Align label text to the left */
  margin-top: 10px;
}

.input-fields {
  display: flex;
  align-items: center; /* Center checkbox and inputs vertically */
  flex: 2; /* Allow input fields to take more space */
  justify-content: center; /* Center the checkbox and inputs */
}

.input-field {
  margin-left: 10px; /* Add space between checkbox and input fields */
}

.checkbox {
  display: flex; /* Align checkbox and label */
  align-items: center; /* Center vertically */
  margin-top: 10px;
  margin-right: 20px; /* Space between checkbox and input fields */
  margin-left: 20px; /* Added margin to create spacing from the label */
}

.checkbox input {
  width: 20px; /* Set width for the checkbox */
  height: 20px; /* Set height for the checkbox to keep it square */
}

.pdf-links-container {
  display: flex; /* Use flexbox for two columns */
  justify-content: center; /* Center the columns */
  margin-top: 10px; /* Space above the PDF links */
  width: 100%; /* Ensure it takes full width */
  margin-bottom: 30px;
}

.pdf-column {
  flex: 1; /* Each column takes equal space */
  margin: 0 20px; /* Space between columns */
  min-width: 200px; /* Set a minimum width for each column */
  max-width: 350px; /* Optional: Set a maximum width for each column */
}

.pdf-column a {
  display: block; /* Each link on a new line */
  margin: 5px 0; /* Space between links */
  padding-left: 20px; /* Indent links from the left */
  text-align: left; /* Align link text to the left */
}

.pdf-header {
  font-weight: bold; /* Make the text bold */
  margin-bottom: 10px; /* Space below each section header */
  text-align: left; /* Align text to the left */
}

.centered-links {
  margin-top: 20px;
  margin-left: 10px; /* Adjust this value for desired spacing */
  text-align: left; /* Align text to the left */
  max-width: 850px; /* Optional: Set a max width for better alignment */
}

.link-spacing {
  margin-left: 5px; /* Adjust this value for desired spacing */
}